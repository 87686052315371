import { ethers, Event } from "ethers";
import { TransactionBlockScout } from "../../utils/types";
import { FormattedTransfer } from "./types";

export const formatTransfer = async (transfer: Event) => {
    const block = await transfer.getBlock();
    const amount = Number(ethers.utils.formatEther(transfer.args?.value));

    return {
        id: transfer.transactionHash,
        from: transfer.args?.from,
        to: transfer.args?.to,
        timestamp: block.timestamp,
        amount,
        reasonForPayment: "",
    };
};

export const formatBlockscoutTransfer = async (
    transfer: TransactionBlockScout,
) => {
    const amount = Number(ethers.utils.formatEther(transfer.data));

    return {
        id: transfer.transactionHash,
        from: transfer.topics[1].replace("000000000000000000000000", ""),
        to: transfer.topics[2].replace("000000000000000000000000", ""),
        timestamp: Number(transfer.timeStamp),
        amount,
        reasonForPayment: "",
    } as FormattedTransfer;
};
