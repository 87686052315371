import CircleIcon from "@mui/icons-material/Circle";
import HistoryIcon from "@mui/icons-material/History";
import PaymentsIcon from "@mui/icons-material/Payments";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { MenuOptions, PathOptions } from "./utils/types";

import { ethers } from "ethers";

const currentEnv = process.env.REACT_APP_ENV;

export const explorerUrl =
    currentEnv === "DEV"
        ? "https://test-explorer.mitgate.com"
        : "https://explorer.mitgate.com/";

export const provider = new ethers.providers.JsonRpcProvider(
    process.env[`REACT_APP_BLOCKCHAIN_PROVIDER_${currentEnv}`],
);

export const chainId = process.env[`REACT_APP_CHAIN_ID_${currentEnv}`] || "";

export const safeProxyAddress =
    process.env[`REACT_APP_SAFE_PROXY_ADDRESS_${currentEnv}`] || "";

export const multiSendAddress =
    process.env[`REACT_APP_MULTI_SEND_ADDRESS_${currentEnv}`] || "";

export const safeMasterCopyAddress =
    process.env[`REACT_APP_SAFE_MASTER_COPY_ADDRESS_${currentEnv}`] || "";

export const safeProxyFactoryAddress =
    process.env[`REACT_APP_SAFE_PROXY_FACTORY_ADDRESS_${currentEnv}`] || "";

export const adminOperations = {
    CHANGE_THRESHOLD: "Cambio threshold voti",
    SET_ALLOWANCE: "Cambio platfond admin",
    EXECUTE_TRANSFER: "Accredito MIT",
};

export const adminOperationsArgs: any = {
    newThreshold: "Threshold richiesta",
    receiver: "Address ricevente",
    amount: "Valore",
    newLimit: "Nuovo limite",
    resetTime: "Tempo di reset",
};

export const regex = {
    privateKey: /^0x[a-fA-F0-9]{64}$/,
};

export const menu = [
    {
        text: "Utenti",
        icon: PersonIcon,
        path: "/users",
    },
    {
        text: "Vip status e locking",
        icon: WorkspacePremiumIcon,
        path: "/vip-status",
    },
    {
        text: "Pagamenti",
        icon: PaymentsIcon,
        path: "/payments",
    },
    {
        text: "Transazioni",
        icon: HistoryIcon,
        path: "/transactions",
    },
    {
        text: "Limiti annui e operazioni",
        icon: CircleIcon,
        path: "/operations-limits",
    },
    {
        text: "Pannello admin",
        icon: CircleIcon,
        path: "/admin-panel",
    },
    {
        text: "Impostazioni profilo",
        icon: SettingsIcon,
        path: "/profile-settings",
    },
] as MenuOptions;

export const pagesPaths = [
    {
        text: "Registra merchant",
        path: "/users/:category/new-merchant/:id",
    },
    {
        text: "Info wallet",
        path: "/merchants/info-wallet",
    },
    {
        text: "Info wallet",
        path: "/merchants/detail",
    },
    {
        text: "Utenti",
        path: "/users",
    },
    {
        text: "Vip status e locking",
        path: "/vip-status",
    },
    {
        text: "Pagamenti",
        path: "/payments",
    },
    {
        text: "Transazioni",
        path: "/transactions",
    },
    {
        text: "Limiti annui e operazioni",
        path: "/operations-limits",
    },
    {
        text: "Pannello admin",
        path: "/admin-panel",
    },
    {
        text: "Impostazioni profilo",
        path: "/profile-settings",
    },
] as PathOptions;

export const baseUrl =
    process.env[`REACT_APP_BLOCKSCOUT_BASE_URL_${currentEnv}`];

// Modules
export const moduleLogs = "module=logs";

// Actions
export const actionGetLogs = "action=getLogs";

// Topics
export const transferTopic =
    "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef";

export const userStackedTopic =
    "0x91405db2584f6e8463494f0e18c40f87fe3146322d19c494e8a0e0a039c38869";

export const userUnstackedTopic =
    "0x668647a3bf871c3ff8779af5157eda0c0d6de3f7af4a3468d3ecc571fc413adb";
