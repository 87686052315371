import { useState } from "react";
import MTFilterBar from "../../components/MTFilterBar";
import MTTable from "../../components/MTTxnsTable";
import useStyles from "./style";

import { getTransfers } from "../../store/transfers/transfers.slice";
import { useSelector } from "react-redux";

const tableHead = [
    "ID transazione",
    "Wallet pagante",
    "Wallet destinatario",
    "Importo",
    "Azioni",
];

const filters = [
    {
        key: "id",
        text: "Ricerca wallet",
        inputType: "input",
    },
    {
        key: "amount",
        text: "Importo MIT",
        inputType: "input",
    },
];

const TransactionHistory: React.FC = () => {
    const classes = useStyles();
    const [activeFilters, setActiveFilters] = useState<any>({});

    const { list: transfers } = useSelector(getTransfers);

    console.log(transfers);

    const filterData = () => {
        let filteredData;
        if (activeFilters.id || activeFilters.amount) {
            filteredData = [...transfers]
                .reverse()
                .filter(
                    txn =>
                        (txn.from
                            .toLowerCase()
                            .includes(activeFilters.id.toLowerCase()) ||
                            txn.to
                                .toLowerCase()
                                .includes(activeFilters.id.toLowerCase()) ||
                            !activeFilters.id) &&
                        (txn.amount == activeFilters.amount ||
                            !activeFilters.amount),
                );
        } else {
            filteredData = [...transfers].reverse();
        }

        return filteredData;
    };

    return (
        <div className={classes.container}>
            <MTFilterBar filters={filters} setFilters={setActiveFilters} />
            <MTTable tableHead={tableHead} tableRows={filterData()} />
        </div>
    );
};
export default TransactionHistory;
