import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { mitTokenContract, provider } from "../../utils/utils";
import { transfersReducer } from "./transfers.reducer";
import { FormattedTransfer, Transfers } from "./types";
import { RootState } from "../store";

import { formatBlockscoutTransfer, formatTransfer } from "./fetchUtils";
import {
    actionGetLogs,
    baseUrl,
    moduleLogs,
    transferTopic,
} from "../../constants";
import { TransactionBlockScout } from "../../utils/types";

// Define initial state
export const transfersInitialState: Transfers = {
    loader: false,
    list: [],
    fromBlock: 0,
};

// State slice
export const transfersSlice = createSlice({
    name: "transfers",
    initialState: transfersInitialState,
    reducers: transfersReducer,
    extraReducers: builder => {
        let loadingToast: React.ReactText;

        builder.addCase(fetchTransfers.pending, state => {
            loadingToast = toast.loading("Caricamento trasferimenti in corso");

            state.loader = true;
        });

        builder.addCase(fetchTransfers.fulfilled, (state, { payload }) => {
            state.list = state.list.concat(payload);

            toast.dismiss(loadingToast);
            toast.success("Dati trasferimenti caricati!");

            state.loader = false;
        });

        builder.addCase(fetchTransfers.rejected, state => {
            toast.dismiss(loadingToast);
            toast.error("Errore caricamento dati trasferimenti");

            state.loader = false;
        });
    },
});

// Action creators
export const {
    actions: { setLoader, addTransfer, setFromBlock },
} = transfersSlice;

// Getters
export const getTransfers = (state: RootState): Transfers => state.transfers;

// Async thunks
export const fetchTransfers = createAsyncThunk<FormattedTransfer[], void>(
    "transfers/fetchTransfers",
    async (_, { rejectWithValue, getState, dispatch }) => {
        const {
            transfers: { fromBlock },
        } = getState() as { transfers: { fromBlock: number } };

        let transfers: FormattedTransfer[] = [];

        let lastBlock = fromBlock;

        let lastResponse = [];

        do {
            const transferResponse = await fetch(
                `${baseUrl}?${moduleLogs}&${actionGetLogs}&fromBlock=${lastBlock}&toBlock=latest&address=${mitTokenContract.address}&topic0=${transferTopic}`,
            );

            const transferData = await transferResponse.json();

            lastResponse = transferData.result;

            const currentBlock = await provider.getBlockNumber();
            dispatch(setFromBlock(currentBlock + 1));

            try {
                lastBlock = Number(
                    transferData.result[transferData.result.length - 1]
                        .blockNumber + 1,
                );
                transfers = [
                    ...transfers,
                    ...(await Promise.all(
                        transferData.result
                            .filter(
                                (transfer: TransactionBlockScout) =>
                                    Number(transfer.logIndex) === 0,
                            )
                            .map(async (transfer: TransactionBlockScout) => {
                                return await formatBlockscoutTransfer(transfer);
                            }),
                    )),
                ];
            } catch (error) {
                console.log(error);
                rejectWithValue(error);
            }
        } while (lastResponse.length >= 1000);

        return transfers;
    },
);
